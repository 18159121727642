import * as React from "react";
import { useEffect } from "react";
import firebase from "gatsby-plugin-firebase";
import { PageProps } from "gatsby";
import { LocalizedLink as Link } from "gatsby-theme-i18n";
import Zoom from "react-reveal/Zoom";
import { StaticImage } from "gatsby-plugin-image";
import { useTranslation } from "react-i18next";
import { toast } from "react-hot-toast";
import { MdDoneAll, MdSchool } from "react-icons/md";
import { useUserInfo } from "../hooks/userInfo";
import useStaticAuthState from "../hooks/staticAuthState";
import Seo from "../components/seo";
import StandardToaster from "../components/standardToaster";

const IndexPage: React.FC<PageProps> = ({ location }) => {
  const [user] = useStaticAuthState(firebase.auth);
  const userInfo = useUserInfo(user);
  const { t } = useTranslation(["translation", "login", "applyInstitution"]);
  useEffect(() => {
    if (localStorage.noInstitutionalPromo === "111") return;
    const toastId = toast(
      (tost) => (
        <div className="flex flex-col justify-center items-center">
          <p className="text-lg">{t("applyInstitution:instutionalPromo")}</p>
          <div className="flex flex-row flex-wrap justify-center items-center gap-2">
            <Link
              to="/apply-institution"
              className="flex justify-center items-center gap-2 cursor-pointer bg-orange text-white
                transition duration-500 ease-in-out transform hover:scale-105
                text-xl text-center px-2 rounded-lg"
            >
              <MdDoneAll className="w-5 h-5" />
              <span>{t("applyInstitution:apply")}</span>
            </Link>
            <button
              type="button"
              className="cursor-pointer bg-gray-400 text-white
                transition duration-500 ease-in-out transform hover:scale-105
                text-xl text-center px-2 rounded-lg"
              onClick={() => {
                localStorage.noInstitutionalPromo =
                  localStorage.noInstitutionalPromo == null ? 1 : localStorage.noInstitutionalPromo + 1;
                toast.dismiss(tost.id);
              }}
            >
              Dismiss
            </button>
          </div>
        </div>
      ),
      {
        icon: <MdSchool className="text-orange w-6 h-6" />,
      }
    );
    return () => {
      toast.dismiss(toastId);
    };
  }, [t]);
  return (
    <div className="flex flex-col justify-evenly items-center bg-blue-100 h-full">
      <Seo title={t("title")} />
      <StandardToaster position="top-center" />
      <div className="flex flex-shrink justify-center items-center w-3/4 md:w-1/2">
        <Zoom>
          <StaticImage
            src="../images/logo_qub.png"
            alt="qubtimetable logo"
            placeholder="tracedSVG"
            layout="constrained"
          />
        </Zoom>
      </div>
      <div className="flex justify-center flex-col w-full items-center space-y-2">
        {user == null ? (
          <>
            <Link
              to={`/login?redirect=${location.pathname}`}
              className="cursor-pointer w-1/2 md:w-1/4 bg-orange text-white
                transition duration-500 ease-in-out transform hover:scale-105
                text-lg text-center py-2 rounded-lg"
            >
              {t("login:login")}
            </Link>
            <Link
              to="/timetable"
              className="cursor-pointer w-1/2 md:w-1/4 bg-blue-200 text-white
                transition duration-500 ease-in-out transform hover:scale-105
                text-lg text-center py-2 rounded-lg"
            >
              {t("translation:publicTimetables")}
            </Link>
          </>
        ) : (
          <Link
            to="/app/timetable"
            className="cursor-pointer w-1/2 md:w-1/4 bg-blue-200 text-white
                 transition duration-500 ease-in-out transform hover:scale-105
                 text-lg text-center py-2 rounded-lg"
          >
            {t("accessTimetables")}
          </Link>
        )}
        {userInfo?.isOwner === true ? (
          <Link
            to="/owner/"
            className="cursor-pointer w-1/2 md:w-1/4 bg-orange text-white
                transition duration-500 ease-in-out transform hover:scale-105
                text-lg text-center py-2 rounded-lg"
          >
            {t("controlPanel")}
          </Link>
        ) : null}
        {user == null ? null : (
          <Link
            to="/app/account"
            className="cursor-pointer w-1/2 md:w-1/4 bg-white text-blue-100
                transition duration-500 ease-in-out transform hover:scale-105
                text-lg text-center py-2 rounded-lg"
          >
            {t("account")}
          </Link>
        )}
        {typeof window !== "undefined" && window.matchMedia("(display-mode: standalone)").matches ? null : (
          <Link
            to="/download"
            className="cursor-pointer text-white w-1/2 md:w-1/4 bg-orange text-blue-100
                transition duration-500 ease-in-out transform hover:scale-105
                text-lg text-center py-2 rounded-lg"
          >
            {t("downloadApps")}
          </Link>
        )}
        <Link
          to="/info"
          className="cursor-pointer text-white w-1/2 md:w-1/4 bg-orange text-blue-100
                transition duration-500 ease-in-out transform hover:scale-105
                text-lg text-center py-2 rounded-lg"
        >
          {t("info")}
        </Link>
      </div>
    </div>
  );
};

export default IndexPage;
