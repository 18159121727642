import firebase from "gatsby-plugin-firebase";
import {UserInfo} from "../util/enpoints/types";
import {useEffect, useState} from "react";
import axios from "axios";
import {getUserInfo} from "../util/enpoints";
import {toast} from "react-hot-toast";
import {useLocalization} from "gatsby-theme-i18n";

export function useUserInfo(user: firebase.User | null | undefined): null | UserInfo {
    const {locale} = useLocalization()
    const [userInfo, setUserInfo] = useState<UserInfo>(null)
    useEffect(() => {
        let cancelToken = axios.CancelToken.source();
        let cleanup = false;

        async function getData() {
            try {
                cancelToken = axios.CancelToken.source();
                let response = await getUserInfo(locale, cancelToken.token)
                if (cleanup) {
                    return;
                }
                setUserInfo(response.data)
            } catch (e) {
                if (axios.isAxiosError(e)) {
                    for (let errMsg of e.response.data.errors) {
                        toast.error(errMsg);
                    }
                } else {
                    throw e;
                }
            }
        }

        if (user != null) {
            getData();
        }
        return () => {
            cleanup = true;
            cancelToken.cancel('Only one request can be performed');
        };
    }, [user])
    return userInfo;
}
